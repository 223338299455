/* src/Cart.css */

.cart {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
}

.cart h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.cart-item-details {
  flex: 1;
  text-align: left;
}

.cart-item h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.cart-item p {
  font-size: 14px;
  color: #555;
}

.price {
  font-weight: bold;
}

.location {
  color: #888;
}

.btn-remove {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-remove:hover {
  background-color: #c0392b;
}

.btn-remove svg {
  margin-right: 5px;
}

.cart-details {
  margin-top: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.btn-save {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.btn-save:hover {
  background-color: #2ecc71;
}

/* Responsivo */
@media (max-width: 600px) {
  .cart {
    padding: 15px;
    max-width: 100%;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-remove {
    margin-top: 10px;
  }
}
