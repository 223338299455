/* Container principal */
.news-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
}

/* Título da seção de notícias */
.news-title {
  text-align: left;
  font-size: 2rem;
  font-weight: 700;
  color: #8A05BE;
  margin-bottom: 20px;
}

/* Grid layout para organizar as notícias */
.news-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Estilos dos cards de notícia */
.news-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  cursor: pointer;
}

.news-card:hover {
  box-shadow: 0 8px 20px rgba(138, 5, 190, 0.3);
  transform: translateY(-5px);
}

/* Header da notícia */
.news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.news-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #8A05BE;
  margin: 0;
  text-transform: capitalize;
}

/* Texto da notícia */
.news-card-text {
  font-size: 1rem;
  color: #4a4a4a;
  line-height: 1.6;
  margin-bottom: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* Footer da notícia */
.news-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}

/* Data e hora da notícia */
.news-card-date {
  font-size: 0.9rem;
  color: #888;
  margin: 0;
}

/* Botão de ação */
.news-button {
  background-color: #8A05BE;
  color: #fff;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.news-button:hover {
  background-color: #6b0491;
}

/* Responsividade */
@media (max-width: 768px) {
  .news-container {
    padding: 10px;
  }

  .news-title {
    font-size: 1.6rem;
  }

  .news-card-title {
    font-size: 1.3rem;
  }

  .news-card-text {
    font-size: 0.95rem;
  }

  .news-button {
    font-size: 0.85rem;
    padding: 6px 14px;
  }
}
/* Rodapé discreto com "Powered by FestaFácil" */
.powered-by-festafacil {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 0.85rem;
  color: #777;
}

.powered-by-festafacil strong {
  color: #3498db;
  font-weight: 600;
}

