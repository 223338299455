/* src/LandingPage.css */

/* Garantir que não haja scroll horizontal */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  overflow-x: hidden;
}

.landing-page {
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #8A05BE;
  color: white;
  width: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.btn-access {
  background-color: white;
  color: #8A05BE;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-access:hover {
  background-color: #eee;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 50px;
  width: 100%;
}

.hero-section h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

.btn-cta {
  background-color: #8A05BE;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-cta:hover {
  background-color: #7A04AC;
}

/* Benefits Section */
.benefits-section {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
}

.benefits-section h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.benefits-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.benefit {
  max-width: 300px;
  margin-bottom: 30px;
}

.benefit h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.benefit p {
  font-size: 16px;
  color: #555;
}

/* Products Section */
.products-section {
  padding: 50px;
  text-align: center;
}

.products-section h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.products-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
}

.product-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-card h3 {
  font-size: 24px;
  margin: 10px 0;
}

.product-card p {
  font-size: 16px;
  color: #555;
}

.price {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.btn-catalog {
  background-color: #8A05BE;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.btn-catalog:hover {
  background-color: #7A04AC;
}

/* Testimonial Section */
.testimonial-section {
  padding: 50px;
  text-align: center;
  background-color: #eee;
}

.testimonial-section h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

blockquote {
  font-style: italic;
  margin: 20px;
  max-width: 600px;
}

footer {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}

/* Footer */
.footer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f1f1f1;
  width: 100%;
  flex-wrap: wrap;
}

.footer-column {
  text-align: left;
  min-width: 200px;
}

.footer h3 {
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer li {
  margin-bottom: 5px;
}

.footer a {
  color: #8A05BE;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
