/* Container da barra de busca */
.search-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilo do input de busca */
.search-input {
  width: 100%;
  padding: 16px 20px;
  border-radius: 35px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 18px;
  margin-bottom: 15px;
  transition: border 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  border-color: #8A05BE;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(138, 5, 190, 0.2);
}

/* Para garantir que o campo de busca tenha tamanho adequado */
.search-bar {
  width: 100%;
  max-width: 600px; /* Ajuste o valor conforme necessário */
  margin: 0 auto;
}

.suggestions-list {
  position: absolute;
  top: 50px;
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
}

.suggestions-list li {
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.no-results {
  padding: 12px 20px;
  color: #999;
  text-align: center;
}

/* Responsividade */
@media (max-width: 768px) {
  .search-container {
    max-width: 90%;
  }
  
  .search-input {
    font-size: 16px;
    padding: 14px 18px;
  }
}

@media (max-width: 480px) {
  .search-container {
    max-width: 100%;
    padding: 0 15px;
  }
}
