.catalog-content {
  padding: 20px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  min-height: 300px; /* Define uma altura mínima para todos os cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card h3 {
  margin-bottom: 10px;
}

.product-card .price {
  color: #8A05BE;
  font-weight: bold;
  margin: 10px 0;
}

.product-card .location {
  margin-bottom: 10px;
}

.product-card .btn-add {
  background-color: #8A05BE;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto; /* Para empurrar o botão para o final do card */
}

.product-card .btn-add:hover {
  background-color: #6d048e;
}

.no-results {
  text-align: center;
  font-size: 18px;
  color: #666;
}
