/* Container do cabeçalho */
/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #2c3e50;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
} */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Garante que o header fique sobre outros elementos */
  background-color: #2c3e50;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Opcional: adicione uma sombra para destacar o header */
}
.logo-link {
  text-decoration: none;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  letter-spacing: 1.5px;
  transition: color 0.3s ease;
}

.logo:hover {
  color: #f1c40f;
}

/* Links de navegação */
.nav-links {
  display: flex;
  gap: 30px;
  transition: all 0.5s ease;
}

.nav-item {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
}

.nav-item:hover {
  background-color: #2980b9;
  color: #f1c40f;
}

/* Botão de Acessar ou Sair */
.btn-access {
  padding: 10px 20px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-access:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

/* Menu hambúrguer */
.hamburger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburger-menu.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  background-color: #f1c40f;
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  background-color: #f1c40f;
}

/* Menu aberto */
.nav-links.open {
  display: block;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #2c3e50;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  gap: 15px;
  z-index: 1000;
}

/* Estilo responsivo */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-links.open {
    display: flex;
  }
}

/* Efeito do menu hambúrguer quando aberto */
.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(1),
.hamburger-menu.open .bar:nth-child(3) {
  background-color: #f1c40f;
}

body {
  padding-top: 60px; /* Adicione um padding-top equivalente à altura do header */
}