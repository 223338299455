.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
}

.footer-column {
  width: 35%;
}

.footer h3 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  color: #3498db;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

/* Balão de aviso sobre cookies */
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  z-index: 1000;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.3);
}

.cookie-consent p {
  margin: 0;
}

.cookie-consent a {
  color: #f1c40f;
  text-decoration: underline;
}

.cookie-consent a:hover {
  text-decoration: none;
}

.accept-cookies-btn {
  background-color: #f1c40f;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-cookies-btn:hover {
  background-color: #d4ac0d;
}
