.chat-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  font-family: 'Arial, sans-serif';
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.chat-title {
  text-align: center;
  color: #4caf50;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.progress-container {
  margin-bottom: 15px;
}

.progress-bar {
  height: 12px;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-top: 5px;
  font-family: 'Poppins', sans-serif;
}

.message-container {
  border: 1px solid #ddd;
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.message-bubble {
  display: block;
  padding: 15px;
  border-radius: 15px;
  max-width: 70%;
  min-width: 150px;
  margin: 10px 0;
  clear: both;
  position: relative;
  animation: slideIn 0.4s ease-in-out;
}

@keyframes slideIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.user-message {
  background-color: #d1e7dd;
  text-align: right;
  float: right;
}

.aliny-message {
  background-color: #e2e3e5;
  text-align: left;
  float: left;
}

.message-divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.message-signature {
  font-size: 10px;
  color: #888;
  position: absolute;
  bottom: 5px;
  right: 10px;
  white-space: nowrap;
}

.typing-indicator {
  display: block;
  padding: 10px;
  background-color: #e2e3e5;
  border-radius: 10px;
  max-width: 70%;
  min-width: 150px;
  margin: 10px 0;
  clear: both;
  position: relative;
  animation: slideIn 0.4s ease-in-out;
}

.typing-dots::after {
  content: '...';
  animation: dots 1.2s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .3em 0 0 rgba(0, 0, 0, 0),
      .6em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #333;
    text-shadow:
      .3em 0 0 rgba(0, 0, 0, 0),
      .6em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow:
      .3em 0 0 #333,
      .6em 0 0 rgba(0, 0, 0, 0);
  }
  80%, 100% {
    text-shadow:
      .3em 0 0 #333,
      .6em 0 0 #333;
  }
}

.chat-input {
  width: 80%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
}

.send-button {
  padding: 12px 25px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover {
  background-color: #45a049;
}

.items-list {
  list-style: none;
  padding: 0;
}

.item-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.item-info {
  flex-grow: 1;
}

.item-info h4 {
  margin: 0;
  font-size: 16px;
}

.item-info p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #555;
}

.add-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #45a049;
}

.budget-container {
  margin-top: 20px;
}

.budget-container h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.budget-container ul {
  list-style-type: none;
  padding: 0;
}

.budget-container li {
  background-color: #e7f3e7;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 5px;
}
.finalize-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.finalize-button:hover {
  background-color: #0056b3;
}
