/* Container principal da tela de orçamentos */
.budgets-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
  font-family: 'Arial', sans-serif;
}

/* Título da seção de orçamentos */
.budgets-container h2 {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-size: 2.6rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2c3e50;
}

/* Mensagem de "nenhum orçamento encontrado" */
.no-budgets-message {
  text-align: center;
  color: #888;
  font-size: 1.4rem;
  margin-top: 40px;
}

/* Grid responsivo para os cards de orçamento */
.budgets-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

/* Cartão de orçamento */
.budget-card {
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 16px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  padding: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
}

/* Efeito de hover no cartão */
.budget-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

/* Informações do orçamento */
.budget-info {
  margin-bottom: 20px;
  text-align: center;
}

.budget-info p {
  margin: 5px 0;
  color: #555;
  font-size: 1.2rem;
}

/* Destaque do valor total */
.budget-total {
  margin: 30px 0;
  text-align: center;
}

.budget-total .label {
  font-size: 1.3rem;
  color: #888;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.total-amount {
  font-size: 3.8rem;
  background: linear-gradient(45deg, #f39c12, #e74c3c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin-top: 5px;
}
.total-amount-sucesso{
  font-size: 3.8rem;
  background: linear-gradient(45deg, #f39c12, #e74c3c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin-top: 5px;
}

/* Botões de ações no cartão */
.budget-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn-edit,
.btn-delete {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-edit {
  background-color: #3498db;
  color: white;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
}

/* Hover nos botões de ação */
.btn-edit:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.btn-delete:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}
/* Rodapé discreto com "Powered by FestaFácil" */
.powered-by-festafacil {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 0.85rem;
  color: #777;
}

.powered-by-festafacil strong {
  color: #3498db;
  font-weight: 600;
}

