/* src/Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
  font-family: 'Arial', sans-serif;
}

.dashboard-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 32px;
  color: #2c3e50;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  color: #3498db;
  margin-bottom: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

.cart-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
}

.cart-item h3 {
  font-size: 22px;
  margin: 0;
  color: #333;
}

.cart-item .price {
  font-size: 16px;
  font-weight: bold;
  color: #27ae60;
}

.cart-item .location {
  font-size: 14px;
  color: #3498db;
}

.cart-item .suppliers {
  margin-top: 10px;
}

.cart-item .suppliers h4 {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 8px;
}

.cart-item .supplier-list {
  padding-left: 15px;
}

.cart-item .supplier {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.cart-item .supplier span {
  font-weight: bold;
  color: #27ae60;
}

.cart-details {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.cart-details p {
  font-size: 16px;
  color: #2c3e50;
  margin: 8px 0;
}

.cart-details strong {
  color: #3498db;
}

.btn-logout {
  background-color: #e74c3c;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.btn-logout:hover {
  background-color: #c0392b;
}

/* Responsividade */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 20px;
  }

  .cart-item h3 {
    font-size: 18px;
  }

  .cart-item .price {
    font-size: 14px;
  }

  .cart-item .supplier {
    font-size: 12px;
  }

  .cart-details p {
    font-size: 14px;
  }
}
