.budget-details-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 30px;
    /* max-width: 800px; */
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .card-title {
    font-size: 24px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Sumário do orçamento */
  .budget-summary {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .summary-item {
    display: flex;
    align-items: center;
    font-size: 23px;
    color: #555;
    flex-basis: calc(50% - 20px);
  }
  
  .summary-item .icon {
    margin-right: 10px;
    font-size: 20px;
    color: #6c63ff;
  }
  
  /* Preço Total */
  .total-price-wrapper {
    text-align: center;
    margin-top: 20px;
  }
  
  .total-price {
    font-size: 28px;
    color: #27ae60;
    font-weight: bold;
    background-color: #ecf9f2;
    padding: 10px 20px;
    border-radius: 8px;
  }
  
  /* Itens */
  .section-title {
    font-size: 20px;
    color: #333;
    margin: 20px 0;
  }
  
  .items-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .item-card {
    background-color: #f9fafb;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }
  
  .item-total-price {
    font-size: 18px;
    color: #2c3e50;
    font-weight: bold;
  }
  
  /* Cotações */
  .quotations-section {
    margin-top: 20px;
  }
  
  .quotations-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .quotation-card {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
  }
  
  .quotation-info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .quoted-price {
    font-size: 23px;
    font-weight: bold;
    color: #e74c3c;
  }
  
  .quotation-actions {
    display: flex;
    gap: 8px;
  }
  
  .btn-accept,
  .btn-contact {
    background-color: #6c63ff;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-accept:hover,
  .btn-contact:hover {
    background-color: #574bde;
  }
  
  /* Footer */
  /* Rodapé discreto com "Powered by FestaFácil" */
.powered-by {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 0.85rem;
  color: #777;
}

.powered-by strong {
  color: #3498db;
  font-weight: 600;
}


  
  /* Ajustes responsivos */
  @media screen and (max-width: 768px) {
    .budget-details-card {
      padding: 20px;
    }
  
    .budget-summary {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .summary-item {
      flex-basis: 100%;
    }
  
    .items-section {
      gap: 12px;
    }
  
    .quotation-actions {
      flex-direction: column;
      gap: 8px;
    }
  
    .total-price-wrapper {
      margin-top: 30px;
    }
  
    .quotations-list {
      gap: 20px;
    }
  }
  