/* src/LoginPage.css */

.login-page {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #8A05BE;
  color: white;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}
.btn-back {
  background-color: white;
  color: #8A05BE;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-back:hover {
  background-color: #2980b9;
}

.login-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.login-form {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.login-form h2 {
  margin-bottom: 20px;
  color: #000;
  font-size: 24px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.btn-login {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.btn-login:hover {
  background-color: #2ecc71;
}

.or-separator {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  color: #888;
}

.btn-google {
  background-color: #db4437;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-google:hover {
  background-color: #c1351d;
}

.btn-google svg {
  margin-right: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.footer-column {
  flex: 1;
  margin-right: 20px;
}

.footer-column h3 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #3498db;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

/* Responsivo */
@media (max-width: 600px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .login-form {
    padding: 15px;
    max-width: 100%;
  }
}
